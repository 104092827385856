import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
  type RouteLocationNormalizedLoaded
} from 'vue-router'

interface Route {
  path: string
  name: string
  component: () => Promise<any>
  meta: {
    title: string
  }
  children?: Route[]
}

const routes: Route[] = [
  {
    path: '/',
    name: 'Layout',
    component: async () => await import('@/layout/LayoutIndex.vue'),
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: async () => await import('@/views/HomePage.vue'),
        meta: {
          title: '网站首页'
        }
      },
      {
        path: '/solution/layout',
        name: 'SolutionLayout',
        component: async () => await import('@/views/SolutionLayout.vue'),
        meta: {
          title: '解决方案'
        },
        children: [
          {
            path: '/solution',
            name: 'Solution',
            component: async () => await import('@/views/SolutionPage.vue'),
            meta: {
              title: '解决方案'
            }
          },
          {
            path: '/solution/pool',
            name: 'Pool',
            component: async () => await import('@/views/PoolPage.vue'),
            meta: {
              title: '游泳馆管理系统解决方案'
            }
          },
          {
            path: '/solution/train',
            name: 'Train',
            component: async () => await import('@/views/TrainPage.vue'),
            meta: {
              title: '校区培训教务解决方案'
            }
          },
          {
            path: '/solution/socialize',
            name: 'Socialize',
            component: async () => await import('@/views/SocializePage.vue'),
            meta: {
              title: '体育社交解决方案'
            }
          },
          {
            path: '/solution/marketing',
            name: 'Marketing',
            component: async () => await import('@/views/MarketingPage.vue'),
            meta: {
              title: '体育营销解决方案'
            }
          },
          {
            path: '/solution/unattended',
            name: 'Unattended',
            component: async () => await import('@/views/UnattendedPage.vue'),
            meta: {
              title: '无人值守球场解决方案'
            }
          },
          {
            path: '/solution/iot',
            name: 'Iot',
            component: async () => await import('@/views/IotPage.vue'),
            meta: {
              title: '智能硬件物联网解决方案'
            }
          }
        ]
      },
      {
        path: '/case/layout',
        name: 'CaseLayout',
        component: async () => await import('@/views/CaseLayout.vue'),
        meta: {
          title: '合作案例'
        },
        children: [
          {
            path: '/case',
            name: 'Case',
            component: async () => await import('@/views/CasePage.vue'),
            meta: {
              title: '合作案例'
            }
          },
          {
            path: '/case/suzhou',
            name: 'Suzhou',
            component: async () => await import('@/views/SuzhouPage.vue'),
            meta: {
              title: '苏州奥林匹克体育中心'
            }
          },
          {
            path: '/case/fuzhou',
            name: 'Fuzhou',
            component: async () => await import('@/views/FuzhouPage.vue'),
            meta: {
              title: '福州海峡奥林匹克体育中心'
            }
          },
          {
            path: '/case/tianjin',
            name: 'Tianjin',
            component: async () => await import('@/views/TianjinPage.vue'),
            meta: {
              title: '天津奥林匹克体育中心'
            }
          },
          {
            path: '/case/guizhou',
            name: 'Guizhou',
            component: async () => await import('@/views/GuizhouPage.vue'),
            meta: {
              title: '贵州双龙生态体育公园'
            }
          },
          {
            path: '/case/dongbei',
            name: 'Dongbei',
            component: async () => await import('@/views/DongbeiPage.vue'),
            meta: {
              title: '东北林业大学体育馆'
            }
          },
          {
            path: '/case/lixiaoshuang',
            name: 'Lixiaoshuang',
            component: async () => await import('@/views/LixiaoshuangPage.vue'),
            meta: {
              title: '李小双运动城'
            }
          }
        ]
      },
      {
        path: '/about',
        name: 'About',
        component: async () => await import('@/views/AboutPage.vue'),
        meta: {
          title: '关于我们'
        }
      }
    ]
  },
  {
    path: '/product',
    name: 'Product',
    component: async () => await import('@/views/ProductPage.vue'),
    meta: {
      title: '产品展示'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition != null) {
      return savedPosition
    }
    return {
      top: 0
    }
  }
})

// 设置标题
router.beforeEach((to: RouteLocationNormalizedLoaded, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  document.title = to.meta.title as string
  next()
})

export default router
