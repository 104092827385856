import {
  createApp
} from 'vue'
import {
  useAmap
} from '@dabu/vue3-amap'
import App from './App.vue'
import 'wow.js/css/libs/animate.css'
import '@dabu/vue3-amap/dist/style.css'

import router from './router'

const {
  initAMapApiLoader
} = useAmap()

void initAMapApiLoader({
  key: '050a33060739c666851fd8d78a4be9f0', // 高德地图 Key
  version: '2.0'
}).then(r => {
  createApp(App)
    .use(router)
    .mount('#app')
})
